import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getAllBenevolatMessagesApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/contactForm/readBenevolatMessages/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteBenevolatMessageApi = async (messageID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/contactForm/deleteBenevolatMessage/${id}/${iv}/${messageID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllEventMessagesApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/contactForm/readEventMessages/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteEventMessageApi = async (messageID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/contactForm/deleteEventMessage/${id}/${iv}/${messageID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllContactMessagesApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/contactForm/readContactMessages/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteContactMessageApi = async (messageID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/contactForm/deleteContactMessage/${id}/${iv}/${messageID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllNewsLetterMessagesApi = async (messageCategory) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/contactForm/readNewsLetterMessages/${id}/${iv}?messageCategory=${messageCategory}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const exportAllNewsLetterMessagesApi = async (messageCategory) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/contactForm/exportNewsLetterMessages/${id}/${iv}?messageCategory=${messageCategory}`
    }
    catch (err) {
        throw err
    }
}

export const deleteNewsLetterMessageApi = async (messageID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/contactForm/deleteNewsLetterMessage/${id}/${iv}/${messageID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
