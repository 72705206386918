import API_URL from '../config';
import axios from 'axios';

export const addNewProducerApi = async(formValues, selectedNumberOfTickets, selectedNumberOfInvitations) => {
    try {
        const objectData = {
            type: formValues?.typeOfCompany === "1" ? "societe" : formValues?.typeOfCompany === "2" ? "association" : formValues?.typeOfCompany === "3" ? "autoentrepreneur" : "" ,
            firstName: formValues?.firstName ?? "",
            lastName: formValues?.lastName ?? "",
            email: formValues?.email ?? "",
            cin: formValues?.cin ?? "",
            companyName: formValues?.companyName ?? "",
            ice: formValues?.ice ?? "",
            if: formValues?.if ?? "",
            bankAccount: formValues?.bankAccount ?? "",
            pseudo: formValues?.pseudo ?? "",
            password: formValues?.password ?? "",
            confirmPass: formValues?.confirmPass ?? "",
            numberOfTickets: {
                isSelected: selectedNumberOfTickets,
                numberOf: formValues?.numberOfTickets ?? 0
            },
            numberOfInvitations: {
                isSelected: selectedNumberOfInvitations,
                numberOf: formValues?.numberOfInvitations ?? 0
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/producer/signUp`, objectData);
        return res.data
    }
    catch (err) {
        throw err
    }
}
