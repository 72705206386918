/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function FormFields({ formValues }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [formDataValues, setFormDataValues] = useState({
    eventTitle: formValues?.eventTitle,
    promoVideo: formValues?.promoVideo,
  });

  const [selectedRadioValue, setSelectedRadioValue] = useState("montant");

  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Information</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                label="Événement"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={selectedRadioValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel value="montant" control={<Radio />} label="Ticket" />
                  <FormControlLabel value="pourcentage" control={<Radio />} label="Invitation" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="number"
                label="Nom"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="number"
                label="Prénom"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="number"
                label="Email"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="number"
                label="N° de téléphone"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <MDTypography variant="h5" mb={4}>Siège</MDTypography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Séléctionner votre siége"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="number"
                label="Nombre de tickets"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Stack spacing={1} sx={{ alignItems: 'center' }}>
      <Stack direction="row" spacing={1}>
        <Chip label="Prix total: " color="success" variant="outlined" />
      </Stack>
    </Stack>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
