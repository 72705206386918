import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

// formik components
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Box, CircularProgress, Dialog } from "@mui/material";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { addNewStandApi, updateStandApi } from "api/stands";

function EventStands({ openEventStand, closeEventStand, refreshData, isEdite, openModalItemData, newStandData }) {
  const [isOnlineChecked, setIsOnlineChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const validationSchema = Yup.object().shape({
    eventStand: Yup.string().required("Saisser le nom de nouvelle stand"),
    eventStandPhone: Yup.string().required("Saisser le numéro de téléphone de nouvelle stand"),
    eventStandLoacation: Yup.string().required("Saisser la localisation de nouvelle stand"),
  });

  useEffect(() => {
    getInitialValue()
  }, [openModalItemData, isEdite])

  const getInitialValue = () => {
    try {
      if(isEdite) {
        setIsOnlineChecked(openModalItemData?.isOnline)
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleAddNewEventStand = async (value) => {
    try {
      const formValues = {
        name: value.eventStand,
        phone: value.eventStandPhone,
        location: value.eventStandLoacation,
        isOnline: isOnlineChecked
      };

      // await addNewStandApi(formValues);
      // Swal.fire({
      //   title: "Ajoutée",
      //   text: "",
      //   icon: "success",
      // });

      newStandData(formValues)

      refreshData();
      closeEventStand();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateEventStand = async (value) => {
    try {
      const formValues = {
        name: value.eventStand,
        phone: value.eventStandPhone,
        location: value.eventStandLoacation,
        isOnline: isOnlineChecked
      };

      await updateStandApi(formValues, openModalItemData._id)
      Swal.fire({
        title: "Ajoutée",
        text: "",
        icon: "success",
      });

      refreshData();
      closeEventStand();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Dialog open={openEventStand} onClose={closeEventStand} fullWidth maxWidth="sm">
        <MDBox>
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  eventStand: isEdite ? openModalItemData?.name : "",
                  eventStandPhone: isEdite ? openModalItemData?.phone : "",
                  eventStandLoacation: isEdite ? openModalItemData?.location : "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  isEdite ? handleUpdateEventStand(values) : handleAddNewEventStand(values)
                }}
              >
                <Form autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox>
                        <MDBox mb={1.625}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventStand">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      label="Nom"
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventStandPhone">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      label="Téléphone"
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventStandLoacation">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      label={isOnlineChecked ? "Lien de votre site web" : "Localisation"}
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isOnlineChecked}
                                    onChange={() => setIsOnlineChecked(!isOnlineChecked)}
                                  />
                                }
                                label={isOnlineChecked ? "Online" : "Offline"}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                        {isLoaded ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <MDButton type="submit" variant="gradient" color="dark">
                            {isEdite ? "Modifier" : "Ajouter"}
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </div>
  );
}

EventStands.propTypes = {
  openEventStand: PropTypes.bool.isRequired,
  closeEventStand: PropTypes.func.isRequired,
};

export default EventStands;
