/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import productImage from "assets/images/products/product-11.jpg";
import meetUpLogo from "assets/images/meetup/logo3.jpg";
import defaultLogo from "assets/images/meetup/logo3.jpg";

import { useParams } from "react-router-dom";
import API_URL from 'config';
import { getOneEventImgsApi } from "api/events";

function EventImages({ imgValues }) {
  const { eventID } = useParams()
  const [imageSrc, setImageSrc] = useState([meetUpLogo]);

  useEffect(() => {
    getInitialData()
  }, [eventID])

  async function urlToFile(url, fileName, mimeType) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], fileName, { type: mimeType });
  }

  const getInitialData = async () => {
    try {
      const oneEventImg = await getOneEventImgsApi(eventID);

      // Map through otherEventImgs and convert each to a file URL
      const imageFiles = await Promise.all(
        oneEventImg.otherEventImgs.map(async (img) => {
          const file = await urlToFile(
            `${API_URL}/${img.path}`,
            img.filename,
            img.mimetype
          );
          return file;
        })
      );
  
      const imageUrls = imageFiles.map((file) => URL.createObjectURL(file));
  
      // Set the array of image URLs to imageSrc state
      setImageSrc(imageUrls);
  
      // Call imgValues with array of files to pass them up to parent
      imgValues(imageFiles);
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleImageChange = (event) => {
    // const file = event.target.files[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     setImageSrc(e.target.result);
    //     imgValues(file);
    //   };
    //   reader.readAsDataURL(file);
    // }
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImageSrc(newImages);
    imgValues(files); // Pass the array of files to parent
  };

  const triggerFileInput = () => {
    document.getElementById("imagesInput").click();
  };

  const handleRemoveImage = (index) => {
    const updatedImages = imageSrc.filter((_, i) => i !== index);
    setImageSrc(updatedImages);
    imgValues(updatedImages);

    if(updatedImages.length === 0)
      setImageSrc([defaultLogo])
  };

  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header event-selected-images"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        {imageSrc.map((src, index) => (
          <MDBox key={index} position="relative" mb={2} className="imgs">
            <MDBox
              key={index}
              component="img"
              src={src}
              alt={`Event Image ${index + 1}`}
              borderRadius="lg"
              shadow="sm"
              width="100%"
              height="100%"
              position="relative"
              zIndex={10}
              mb={2}
            />
            <MDButton
              variant="outlined"
              color="error"
              size="small"
              onClick={() => handleRemoveImage(index)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                zIndex: 20,
              }}
            >
              Supprimer
            </MDButton>
          </MDBox>
        ))}
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-10}
          position="relative"
          zIndex={1}
        >
          <MDBox mr={1}>
            <MDButton variant="gradient" color="info" size="small" onClick={triggerFileInput}>
              Modifier
            </MDButton>
            <input
              type="file"
              id="imagesInput"
              style={{ display: "none" }}
              onChange={handleImageChange}
              multiple // Allow multiple images
            />
          </MDBox>
          <MDButton variant="outlined" color="dark" size="small" onClick={() => setImageSrc([defaultLogo])}>
            Supprimer Tout
          </MDButton>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          Images d'événement
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular">
          {/* (500 px / 500 px) */}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

EventImages.propTypes = {
  imgValues: PropTypes.string.isRequired,
};

export default EventImages;
