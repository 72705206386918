import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

// formik components
import { Formik, Form, Field, FieldArray } from "formik";
import Swal from "sweetalert2";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Box, CircularProgress, Dialog, IconButton } from "@mui/material";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { addNewPlaceApi, updatePlaceApi } from "api/places";

import { v4 as uuidv4 } from 'uuid';

function EventSelectedSieges({ openEventSelectedSiege, closeEventSelectedSiege, refreshData, isEdite, openModalItemData, newSiegeData }) {
  const [isOnlineChecked, setIsOnlineChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // const validationSchema = Yup.object().shape({
  //   eventPlace: Yup.string().required("Saisser le nom de nouvelle lieu"),
  //   eventLocation: Yup.string().required("Saisser la localisation"),
  // });

  const validationSchema = Yup.object().shape({
    eventSiege: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Saisir le nom de lieu"),
          price: Yup.number().required("Saisir le numéro de téléphone"),
          numberOfPlaces: Yup.number().required("Saisir le nombre limite de places")
        })
      )
      .required("Vous devez ajouter au moins un lieu"),
  });

  useEffect(() => {
    getInitialValue()
  }, [openEventSelectedSiege])

  const getInitialValue = () => {
    try {
      // console.log("openModalItemData", openModalItemData)

      if(isEdite) {
        setIsOnlineChecked(openModalItemData?.isOnline)
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleAddNewEventSiege = async (value) => {
    try {
      // console.log(value)

      // const formValues = {
      //   name: value.eventPlace,
      //   location: value.eventLocation,
      //   isOnline: isOnlineChecked
      // }

      // // await addNewPlaceApi(formValues)
      Swal.fire({
        title: "Ajoutée",
        text: "",
        icon: "success"
      });

      newSiegeData(value)

      // refreshData()
      closeEventSelectedSiege()
    } catch (err) {
      console.log(err)
    }
  };

  const handleUpdateEventPlace = async (value) => {
    try {
      // const formValues = {
      //   name: value.eventPlace,
      //   location: value.eventLocation,
      //   isOnline: isOnlineChecked
      // }

      // await updatePlaceApi(formValues, openModalItemData._id)
      // Swal.fire({
      //   title: "Modifiée",
      //   text: "",
      //   icon: "success"
      // });

      // refreshData()
      // closeEventSelectedSiege();
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <Dialog open={openEventSelectedSiege} onClose={closeEventSelectedSiege} fullWidth maxWidth="lg">
        <MDBox>
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Grid item xs={12}>
            <Formik
                initialValues={{
                  eventSiege: openModalItemData ? openModalItemData : []
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  isEdite ? handleUpdateEventPlace(values) : handleAddNewEventSiege(values);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          <FieldArray name="eventSiege">
                            {({ push, remove }) => (
                              <>
                                {values.eventSiege.map((place, index) => (
                                  <Grid container spacing={1} key={index} alignItems="center">
                                    <Grid item xs={12} sm={3} mb={1.2}>
                                      <Field name={`eventSiege[${index}].name`}>
                                        {({ field, meta }) => (
                                          <>
                                            <MDInput
                                              {...field}
                                              label="Siège"
                                              size="large"
                                              fullWidth
                                              error={meta.error}
                                              success={!meta.error && meta.touched}
                                            />
                                            {meta.touched && meta.error && (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="error"
                                                fontWeight="regular"
                                              >
                                                {meta.error}
                                              </MDTypography>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </Grid>

                                    <Grid item xs={12} sm={3} mb={1.2}>
                                      <Field name={`eventSiege[${index}].price`}>
                                        {({ field, meta }) => (
                                          <>
                                            <MDInput
                                              {...field}
                                              type="number"
                                              label="Prix"
                                              size="large"
                                              fullWidth
                                              error={meta.error}
                                              success={!meta.error && meta.touched}
                                            />
                                            {meta.touched && meta.error && (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="error"
                                                fontWeight="regular"
                                              >
                                                {meta.error}
                                              </MDTypography>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </Grid>

                                    <Grid item xs={12} sm={3} mb={1.2}>
                                      <Field name={`eventSiege[${index}].numberOfPlaces`}>
                                        {({ field, meta }) => (
                                          <>
                                            <MDInput
                                              {...field}
                                              type="number"
                                              label="Nombre des places"
                                              size="large"
                                              fullWidth
                                              error={meta.error}
                                              success={!meta.error && meta.touched}
                                            />
                                            {meta.touched && meta.error && (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="error"
                                                fontWeight="regular"
                                              >
                                                {meta.error}
                                              </MDTypography>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                      <IconButton onClick={() => remove(index)} color="error">
                                        <DeleteIcon fontSize="30px" />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                                <MDButton
                                  type="button"
                                  onClick={() => push({ name: "", price: null, numberOfPlaces: null, id: uuidv4() })}
                                  variant="text"
                                  color="success"
                                  startIcon={<AddIcon />}
                                >
                                  Ajouter un siège
                                </MDButton>
                              </>
                            )}
                          </FieldArray>
                          {isLoaded ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress />
                            </Box>
                          ) : (
                            <MDButton type="submit" variant="gradient" color="dark">
                              {isEdite ? "Modifier" : "Ajouter"}
                            </MDButton>
                          )}
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </div>
  );
}

EventSelectedSieges.propTypes = {
  openEventSelectedSiege: PropTypes.bool.isRequired,
  closeEventSelectedSiege: PropTypes.func.isRequired,
};

export default EventSelectedSieges;
