/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useParams } from "react-router-dom";
import { getOneMediaApi } from "api/medias";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function FormFields({ formValues }) {
  const { mediaID } = useParams()

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [formDataValues, setFormDataValues] = useState({
    eventTitle: formValues?.mediaName || "",
    promoVideo: formValues?.mediaVideo || ""
  });

  useEffect(() => {
    getInitialData()
  }, [mediaID])

  const getInitialData = async () => {
    try {
      const oneMediaDetails = await getOneMediaApi(mediaID)

      const formValueObject = {
        eventTitle: oneMediaDetails.mediaName,
        promoVideo: oneMediaDetails.mediaVideo,
      }

      setFormDataValues(formValueObject)
      formValues(formValueObject)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Information</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Titre"
                name="eventTitle"
                value={formDataValues.eventTitle}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Video"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
