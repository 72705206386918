/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import productImage from "assets/images/products/product-11.jpg";
import meetUpLogo from "assets/images/meetup/logo3.jpg";
import defaultLogo from "assets/images/meetup/logo3.jpg";

function EventImageCover({ imgCoverValue }) {
  const [imageSrc, setImageSrc] = useState(meetUpLogo);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
        imgCoverValue(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("imageInput2").click();
  };

  const handleRemoveImage = () => {
    setImageSrc(defaultLogo);
  };

  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={imageSrc}
          alt="Product Image"
          borderRadius="lg"
          shadow="sm"
          width="100%"
          height="100%"
          position="relative"
          zIndex={10}
          mb={2}
        />
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-10}
          position="relative"
          zIndex={1}
        >
          <MDBox mr={1}>
            <MDButton variant="gradient" color="info" size="small" onClick={triggerFileInput}>
              Modifier
            </MDButton>
            <input
              type="file"
              id="imageInput2"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </MDBox>
          <MDButton variant="outlined" color="dark" size="small" onClick={handleRemoveImage}>
            Suprimer
          </MDButton>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          Image de couverture de l'événement
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular">
          (1200 px / 400 px)
        </MDTypography>
      </MDBox>
    </Card>
  );
}

EventImageCover.propTypes = {
  imgCoverValue: PropTypes.string.isRequired,
};

export default EventImageCover;
