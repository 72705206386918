import React, { createContext, useContext, useEffect } from "react";
import { socket } from "socket";

// Create the context
const SocketContext = createContext(socket);

// Custom hook to use the socket context
export const useSocket = () => useContext(SocketContext);

export default function SocketProvider({ children }) {
  useEffect(() => {
    socket.connect();

    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
    });

    return () => {
      socket.disconnect(); // Clean up on unmount
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
}
