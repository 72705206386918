import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewPlaceApi = async(formValues) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            name: formValues.name,
            location: formValues.location,
            isOnline: formValues.isOnline,
            fromAdmin: id,
            isAdmin: true
        }

        const res = await axios.post(`${API_URL}/api/V2/eventPlace/create/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updatePlaceApi = async(formValues, placeID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            name: formValues.name,
            location: formValues.location,
            isOnline: formValues.isOnline
        }

        const res = await axios.put(`${API_URL}/api/V2/eventPlace/update/${id}/${iv}/${placeID}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllPlacesApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/eventPlace/read/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteOnePlaceApi = async (placeID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/eventPlace/delete/${id}/${iv}/${placeID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
