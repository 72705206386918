/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import MDProgress from "components/MDProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";

function PlatformSettings() {
  return (
    <Card sx={{ boxShadow: "none" }} style={{ width: "100%" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Details
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25} >
        <Grid container spacing={1} mb={5}>
          <Grid item xs={12} md={6}>
            <MDTypography variant="body2" color="text" style={{ fontWeight: "500", fontSize: "0.8rem" }} >
              Progression des tickets (500/1000)
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDProgress value="50" variant="gradient" />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <MDTypography variant="body2" color="text" style={{ fontWeight: "500", fontSize: "0.8rem" }} >
              Taux de remplissage (800/1000)
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDProgress value="80" variant="gradient" />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
