/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function EventDescription({ descriptionValue }) {
  const [editorValue, setEditorValue] = useState("");

  const handleEditorChange = (content) => {
    setEditorValue(content);
    descriptionValue(content);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Description</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  {/* Description&nbsp;&nbsp; */}
                  {/* <MDTypography variant="caption" fontWeight="regular" color="text">
                    (optional)
                  </MDTypography> */}
                </MDTypography>
              </MDBox>
              <MDEditor value={handleEditorChange} />
              {/* <div dangerouslySetInnerHTML={{ __html: editorValue }} /> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default EventDescription;
/* eslint-disable react/prop-types */
