import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Switch from "@mui/material/Switch";

import {
  MenuItem,
  Select,
  SpeedDial,
  SpeedDialAction,
  FormControl,
  Grid,
  InputLabel,
} from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import InfoIcon from "@mui/icons-material/Info";
import MDInput from "components/MDInput";

function EventTableList() {
  return (
    <>
      <Card>
        {/* <DataTable table={tableRowData} entriesPerPage={false} canSearch /> */}

        <TableContainer component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <MDBox width="15rem" ml="" display="flex" justifyContent="right" padding={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" style={{ fontSize: '12px', padding: '0', marginTop: '-4px', color: 'gray', opacity: 0.6 }}>Type d'événement</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type d'événement"
                    style={{ height: "2.1pc" }}
                    // value={value}
                    // onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Tous</em>
                    </MenuItem>
                    <MenuItem value={10}>active</MenuItem>
                    <MenuItem value={20}>en attente</MenuItem>
                    <MenuItem value={30}>terminés</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDBox width="15rem" ml="" display="flex" justifyContent="right" padding={2}>
                <MDInput
                  placeholder="Nom d'événement"
                  size="small"
                  fullWidth
                  // value={search}
                  // onChange={({ currentTarget }) => {
                  //   setSearch(search);
                  //   onSearchChange(currentTarget.value);
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDBox width="15rem" ml="" display="flex" justifyContent="right" padding={2}>
                <MDInput
                  placeholder="Date d'événement"
                  size="small"
                  fullWidth
                  type="date"
                  // value={search}
                  // onChange={({ currentTarget }) => {
                  //   setSearch(search);
                  //   onSearchChange(currentTarget.value);
                  // }}
                />
              </MDBox>
            </Grid>
          </Grid>

          <Table aria-label="simple table">
            <TableHead style={{ display: "table-header-group", important: "true" }}>
              <TableRow>
                {/* <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  Carrousel
                </TableCell> */}
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  événement
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  date
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  chiffre d'affaire
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  pourcentage de la vente
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  état de l'événement
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.7rem",
                    opacity: "0.7",
                    textTransform: "uppercase",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="index" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {/* <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  <Switch
                    checked={false}
                    // onChange={() => handleSliderViewStatus(el._id, !el.showAtSlider)}
                  />
                </TableCell> */}
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  <p>BOUDCHART AGADIR - 20 Août 2024</p>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  <p>15-02-2024</p>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  <p>500 000 Dhs</p>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  <p>38%</p>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  {/* {
                    el.eventStatus === "N" ? <StatusCell icon="replay" color="warning" status="en attente" /> :
                    el.eventStatus === "V" ? <StatusCell icon="done" color="success" status="active" /> :
                    el.eventStatus === "A" ? <StatusCell icon="close" color="error" status="terminé" /> :
                    el.eventStatus === "F" ? <StatusCell icon="close" color="error" status="active" /> : <></>
                  } */}
                  {<StatusCell icon="done" color="success" status="active" />}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: "#7b809a",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    maxWidth: 100,
                  }}
                >
                  <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{ position: "absolute", top: 0, right: "60%" }}
                      className="custom-speed-dial"
                      icon={<MoreIcon />}
                      direction="left"
                    >
                      <SpeedDialAction
                        key={""}
                        icon={<PublishIcon />}
                        tooltipTitle="Mettre en ligne"
                      />
                      <SpeedDialAction
                        key={""}
                        icon={<InfoIcon />}
                        tooltipTitle="Suspendre de l'événement"
                      />
                      <SpeedDialAction key={""} icon={<DeleteIcon />} tooltipTitle="Supprimer" />
                      <SpeedDialAction key={""} icon={<EditIcon />} tooltipTitle="Modifier" />
                    </SpeedDial>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default EventTableList;
