/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Switch } from "@mui/material";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function FormFields({ formValues, invitationValue, ticketsValue }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [formDataValues, setFormDataValues] = useState({
    typeOfCompany: "1"
  });

  const [selectedRadioValue, setSelectedRadioValue] = useState("montant");
  const [limitedTickets, setLimitedTickets] = useState(false)
  const [limitedInvitations, setLimitedInvitations] = useState(false)

  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  const handleSwitchTicketChange = (value) => {
    try {
      setLimitedTickets(value)
      ticketsValue(value)
    }
    catch (err) {
      console.log(err)
    }
  }
  const handleSwitchInvitationChange = (value) => {
    try {
      setLimitedInvitations(value)
      invitationValue(value)
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informations</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Nom"
                name="firstName"
                value={formDataValues.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Prénom"
                name="lastName"
                value={formDataValues.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="CIN (Carte d'Identité Nationale)"
                name="cin"
                value={formDataValues.cin}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="typeOfCompany-label"
                    name="typeOfCompany"
                    value={formDataValues.typeOfCompany}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Société" />
                    <FormControlLabel value="2" control={<Radio />} label="Association" />
                    <FormControlLabel value="3" control={<Radio />} label="Autoentrepreneur" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            {
              formDataValues.typeOfCompany == "1" ?
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Nom de la société"
                  name="companyName"
                  value={formDataValues.companyName}
                  onChange={handleInputChange}
                />
              </Grid> :
              formDataValues.typeOfCompany == "2" ?
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Nom de l'association"
                  name="companyName"
                  value={formDataValues.companyName}
                  onChange={handleInputChange}
                />
              </Grid> :
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Nom de l'autoentrepreneur"
                  name="companyName"
                  value={formDataValues.companyName}
                  onChange={handleInputChange}
                />
              </Grid>
            }
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="ICE (Identifiant Commun d'Entreprise)"
                name="ice"
                value={formDataValues.ice}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="IF (Identifiant Fiscal)"
                name="if"
                value={formDataValues.if}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Compte bancaire"
                name="bankAccount"
                value={formDataValues.bankAccount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Pseudo du producteur"
                name="pseudo"
                value={formDataValues.pseudo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Email"
                name="email"
                value={formDataValues.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="password"
                label="Mot de passe"
                name="password"
                value={formDataValues.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="password"
                label="Confirmer le mot de passe"
                name="confirmPass"
                value={formDataValues.confirmPass}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={1}>
                  <Switch checked={limitedTickets} onChange={() => handleSwitchTicketChange(!limitedTickets)} /> 
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type="number"
                    label="Nombre des tickets"
                    name="numberOfTickets"
                    value={formDataValues.numberOfTickets}
                    onChange={handleInputChange}
                    disabled={!limitedTickets}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={1}>
                  <Switch checked={limitedInvitations} onChange={() => handleSwitchInvitationChange(!limitedInvitations)} /> 
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type="number"
                    label="Nombre des invitations"
                    name="numberOfInvitations"
                    value={formDataValues.numberOfInvitations}
                    onChange={handleInputChange}
                    disabled={!limitedInvitations}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
