/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import Swal from "sweetalert2";

import {
  CircularProgress,
  SpeedDial,
  SpeedDialAction
} from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/DownloadRounded';

// Data
import dataTableData from "./../../../layouts/events/event-page/data/dataTableData";
import { getAllEventApi } from "api/events";
import { getAllCategoriesApi } from "api/categories";
import { deleteEventApi, publishEventApi, eventStatusApi, eventSliderStatusApi } from "api/events";
import { useNavigate } from "react-router-dom";

function EventsList() {
  const navigate = useNavigate();

  const [menu, setMenu] = useState(null);

  const [tableRowData, setTableRowData] = useState([])
  const [categoryData, setCategoryRowData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    getTableData()
  }, [])

  const getTableData = async () => {
    try {
      setIsLoading(true)
      const tableData = await getAllEventApi()
      setTableRowData(tableData)

      const eventCategory = await getAllCategoriesApi()
      setCategoryRowData(eventCategory)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleDeleteEvent = async (ID) => {
    try {
      setIsLoading(true)
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer cette événement",
        icon: 'warning',
        confirmButtonText: 'Supprimer'
    }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteEventApi(ID)
            getTableData()

            Swal.fire(
              'Supprimée',
              '',
              'success'
            )
          }
          catch (err) {
            console.log(err)
          }
        }
    });
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handlePublishEvent = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment publier cette événement",
        icon: 'warning',
        confirmButtonText: 'Publier'
      }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await publishEventApi(ID)
              getTableData()
  
              Swal.fire(
                'Supprimée',
                '',
                'success'
              )
            }
            catch (err) {
              console.log(err)
            }
          }
      });
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleChangeEventStatus = async (ID, status) => {
    try {
      await eventStatusApi(ID, status)
      getTableData()
      Swal.fire(
        status ? "Activée" : "Desactivée",
        '',
        'success'
      )
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleSliderViewStatus = async (ID, status) => {
    try {
      await eventSliderStatusApi(ID, status)
      getTableData()
      Swal.fire(
        status ? "Activée" : "Desactivée",
        '',
        'success'
      )
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleCampaignEditeOpen  = (eventID) => {
    navigate(`/evenements/modifier/${eventID}`);
  }

  const handleCopyClick = () => {
    try {

    }
    catch (err) {
      console.log(err)
    }
  }

  const handleEventDetailsRedirection = (eventID) => {
    navigate(`/evenements/details/${eventID}`);
  }

  const handleDownloadEventReport = (eventID) => {
    try {

    }
    catch (err) {
      console.log(err)
    }
  }

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {
        categoryData.map(el => (
          <MenuItem onClick={closeMenu}>{el.name}</MenuItem>
        ))
      }
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="success" fontWeight="regular">
          Tous
        </MDTypography>
      </MenuItem>
    </Menu>
  )

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="info">
            Ajouter un événement
          </MDButton> */}
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              Filtres par type d'événement
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                Exporter au format CSV
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          {/* <DataTable table={tableRowData} entriesPerPage={false} canSearch /> */}
          <TableContainer component={Paper}>
              <MDBox width="15rem" ml="auto" display="flex" justifyContent="right" padding={2}>
                {/* <MDInput
                  placeholder="Search by name..."
                  size="small"
                  fullWidth
                  // value={search}
                  // onChange={({ currentTarget }) => {
                  //   setSearch(search);
                  //   onSearchChange(currentTarget.value);
                  // }}
                /> */}
              </MDBox>
              <Table aria-label="simple table">
                <TableHead style={{ display: "table-header-group", important: "true" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Carrousel
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Catégorie
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Nom de l'événement
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Date de début
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Date de fin
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Total des tickets
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Chiffre d'affaires
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Statut
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                {
                  isLoading ?
                  <MDBox display="flex" justifyContent="center" alignItems="center" mt={5} mb={5}>
                    <CircularProgress color="info" size={50} />
                  </MDBox>
                  :
                  <TableBody>
                    {tableRowData.map((el, index) => (
                      <TableRow
                        key="index"
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                        <Switch checked={el.showAtSlider} onChange={() => handleSliderViewStatus(el._id, !el.showAtSlider)} />
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p></p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.eventTitle}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.eventDate.split('T')[0]} {el.eventDate.split('T')[1].substr(0, 5)}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.startDate.split('T')[0]} {el.startDate.split('T')[1].substr(0, 5)}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.endDate.split('T')[0]} {el.endDate.split('T')[1].substr(0, 5)}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.sumOfTickets}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.sumOfProfits}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          {/* {
                            el.eventStatus === "N" ? <StatusCell icon="replay" color="warning" status="publiée" /> :
                            el.eventStatus === "V" ? <StatusCell icon="done" color="success" status="inactive" /> :
                            el.eventStatus === "A" ? <StatusCell icon="close" color="error" status="inactive" /> :
                            el.eventStatus === "F" ? <StatusCell icon="close" color="error" status="inactive" /> : <></>
                          } */}
                          {
                            el.isActive && el.isPublished ? <StatusCell icon="done" color="success" status="publiée" />
                            : <StatusCell icon="close" color="error" status="pas publiée" />
                          }
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                            <SpeedDial
                              ariaLabel="SpeedDial basic example"
                              sx={{ position: "absolute", top: 0, right: "60%" }}
                              className="custom-speed-dial"
                              icon={<MoreIcon />}
                              direction="left"
                            >
                              {
                                !el.isActive ?
                                <SpeedDialAction
                                  key={""}
                                  icon={<PlayCircleFilledIcon />}
                                  tooltipTitle="Activer"
                                  onClick={() => handleChangeEventStatus(el._id, true)}
                                /> :
                                <SpeedDialAction
                                  key={""}
                                  icon={<StopCircleIcon />}
                                  tooltipTitle="Desactiver"
                                  onClick={() => handleChangeEventStatus(el._id, false)}
                                />
                              }
                              {
                                !el.isPublished &&
                                <SpeedDialAction
                                  key={""}
                                  icon={<PublishIcon />}
                                  tooltipTitle="Mettre en ligne"
                                  onClick={() => handlePublishEvent(el._id)}
                                />
                              }
                              <SpeedDialAction
                                key={""}
                                icon={<EditIcon />}
                                tooltipTitle="Modifier"
                                onClick={() => handleCampaignEditeOpen(el._id)}
                              />
                              <SpeedDialAction
                                key={""}
                                icon={<InfoIcon />}
                                tooltipTitle="Details"
                                onClick={() => handleEventDetailsRedirection(el._id)}
                              />
                              <SpeedDialAction
                                key={""}
                                icon={<DownloadIcon />}
                                tooltipTitle="Rapport"
                                onClick={() => handleDownloadEventReport(el._id)}
                              />
                              <SpeedDialAction
                                key={""}
                                icon={<DeleteIcon />}
                                tooltipTitle="Supprimer"
                                onClick={() => handleDeleteEvent(el._id)}
                              />
                            </SpeedDial>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                }

              </Table>
            </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventsList;
